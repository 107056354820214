<template>
	<!--  type : 07(방송예정), 10(라이브), 20(VOD)  -->
	<article class="live-card">
		<i
			class="video-badge"
			:class="{
				'video-badge-comingsoon': type === '07' || type === '08' || type === '09',
				'video-badge-live': type === '10',
				'video-badge-vod': type === '20',
			}"
		>
		</i>


    <!-- coming soon -->
    <button v-if="type === '07' || type === '08' || type === '09'" type="button" @click="clickCommingSoon" class="live-video-link">
      <div class="live-video">
        <div class="video-date">
          <p class="video-date-day">
            {{ dateDay }}<span class="video-date-day__week">({{ dateDayWeek }})</span>
          </p>
          <p class="video-date-time">{{ dateTime }}</p>
        </div>
        <div class="video-player">
          <img :src="img" :alt="alt" />
        </div>
        <div class="video-content">
          <div class="video-content-group">
            <p class="video-content-title">{{ subDesc }}</p>
            <p class="video-content-desc"></p>
          </div>
          <div class="video-content-reflink" v-if="!!relLink">
            <a :href="relLink" target="_blank" title="새창 열림" class="button">관련페이지</a>
          </div>
        </div>
      </div>
    </button>

    <!-- live-->
    <button v-if="type === '10'" type="button" @click="$emit('clickfunc')" class="live-video-link">
      <div class="live-video">
        <div class="video-date">
          <p class="video-date-day">
            {{ dateDay }}<span class="video-date-day__week">({{ dateDayWeek }})</span>
          </p>
          <p class="video-date-time">{{ dateTime }}</p>
        </div>
        <div class="video-player">
          <img :src="img" :alt="alt" />
        </div>
        <div class="video-content">
          <div class="video-content-group">
            <p class="video-content-title">{{ subDesc }}</p>
            <p class="video-content-desc"></p>
          </div>
          <div class="video-content-reflink" v-if="!!relLink">
            <a :href="relLink" target="_blank" title="새창 열림" class="button">관련페이지</a>
          </div>
        </div>
      </div>
    </button>

		<!-- vod -->
		<a v-if="type === '20'" :href="vodLink" class="live-video-link" :target="isOpenTab ? '_blank' : '_self'">
			<div class="live-video">
        <!--  2023-05-25 정유진 주임 요청으로 조회수 숨김
				<div class="video-watch">
					<i class="icon-watch"></i><span class="text">{{ watch }}</span>
				</div>
        -->
				<div class="video-player">
					<img :src="img" :alt="alt" />
				</div>
				<div class="video-content">
					<div class="video-content-group">
						<p class="video-content-title">{{ subDesc }}</p>
            <p class="video-content-desc"></p>
					</div>
					<div class="video-content-reflink" v-if="!!relLink">
						<a :href="relLink" target="_blank" title="새창 열림" class="button">관련페이지</a>
					</div>
				</div>
			</div>
		</a>

	</article>
</template>

<script>
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
export default {
	name: 'BroadcastCard',
	props: {
		type: null,
		watch: Number,
		vodLink: null,
		dateDay: String,
		dateDayWeek: String,
		dateTime: String,
		img: String,
		alt: String,
		title: String,
		subDesc: String,
		relLink: String,
    isOpenTab: {
      type: Boolean
      , default: false
    }
	},
	methods: {
    clickCommingSoon(){
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '방송 시간이 아닙니다.' });
    }
  },
};
</script>
