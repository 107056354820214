<template>
	<main class="page-container">
		<!-- s: 판로TV VOD -->
		<section class="broadcast-vod" data-fill="true">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">판로TV VOD</h3>
				</div>
				<!-- VOD 제목 -->
				<div class="broadcast-vod-header">
					<p class="broadcast-vod-header__cate"></p>
					<!--<p class="broadcast-vod-header__cate">[이커머스 입점설명회]</p>-->
					<div class="broadcast-vod-header__title">
						<p class="title">
							{{ brcTit }}
						</p>
						<span class="date"></span>
					</div>
				</div>
				<!-- //VOD 제목 -->

				<!-- VOD player -->
				<div class="vod-wrap">
					<iframe name="myplayer" id="myplayer" :src="playerUrl" frameborder="0" width="600" height="400" allow="encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<!-- //VOD player -->

				<!-- VOD 도구 -->
				<div class="broadcast-vod-tools">
					<button type="button" class="button" :class="{ 'is-active': soundOn }" @click="toggleSound">
						<!-- <i class="icon icon-sound-off"></i> -->
						<i class="icon icon-vod-soundoff"></i>
					</button>
					<button type="button" class="button" :class="{ likeVod: liked }" @click="addRecommCnt">
						<i class="icon icon-vod-like"></i>
						<span class="text">좋아요</span>
						<span class="nums">{{ recommCnt }}</span>
					</button>
					<button type="button" class="button" @click="copyUrl">
						<i class="icon icon-vod-share"></i>
						<input type="text" id="urlInput" readonly style="position: absolute; left: -10000px" />
						<span class="text">공유</span>
					</button>

					<a v-if="relLink" :href="relLink" target="_blank" title="새창 열림" class="button">
						<i class="icon icon-vod-link"></i>
						<span class="text">관련페이지</span>
					</a>
				</div>
				<!-- //VOD 도구 -->
				<!-- VOD 공지 -->
				<div class="broadcast-vod-notice" v-html="notice"></div>
				<!-- //VOD 공지 -->
			</div>
		</section>
		<!-- e: 판로TV VOD -->

		<!-- s: 카테고리별 방송 -->
		<section class="broadcast-section broadcast-section--bg">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">카테고리별 방송</h3>
					<nav class="page-nav">
						<ul class="nav-list">
							<li class="nav-item"><a href="/playground" class="router-link-active"></a></li>
							<li class="nav-item" :class="{ 'is-selected': programCd == '' || !programCd }">
								<button type="button" class="nav-text" @click="getVODList('')">전체</button>
							</li>
							<li v-for="(program, idx) in programList" :key="idx" class="nav-item" :class="{ 'is-selected': programCd === program.PROGRAM_CD }">
								<button type="button" class="nav-text" @click="getVODList(program.PROGRAM_CD)">{{ program.PROGRAM_NM }}</button>
							</li>
						</ul>
					</nav>
				</div>
				<div class="broadcast-component broadcast-component-category">
					<div class="toggle-header-alarm">
						<!--						<toggle-switch :model="smsList" :value="programCd" class="color-orange" label="방송 알림받기" @changefunc="smsListChg2(programCd)" />-->
						<toggle-switch v-if="programCd" :propModel="smsList" :value="programCd" class="color-orange" label="방송 알림받기" @changefunc="smsListChg2(programCd)" />
					</div>
					<swiper class="swiper" ref="mySwiper" :options="swiperOptionCate">
						<swiper-slide v-for="(item, idx) in vodList" :key="idx">
							<!--  BRC_STS_CD : 07(방송예정), 10(라이브), 20(VOD)       -->
							<broadcast-card type="20" :watch="item.INQ_CNT" :vodLink="`/broadcast/${item.BRC_INFO_NO}/view`" :img="`${item.BRC_RPRS_IMG_URL}`" :alt="item.BRC_TIT" :title="item.TITLE" :subDesc="item.BRC_TIT" :relLink="item.REL_LINK" @clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination" slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</section>
		<!-- e: 카테고리별 방송 -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import BroadcastCard from '../../../components/broadcast/BroadcastCard';
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_BRC_INFO, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_INSERT_PROG_SMSUSER, ACT_INSERT_RECOMM_CNT } from '../../../store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import { getItems } from '@/assets/js/utils';
const jsonp = require('jsonp');

let controller, config;
let isLoginGuide = false;
let previewTime = 3 * 60; //비회원 3분 미리보기
export default {
	name: 'VodView',
	components: {
		ToggleSwitch,
		BroadcastCard,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('broadcast', ['broadcastVod']),
		vodProgramCd: function () {
			var res = '';
			for (var i = 0; i < this.smsList.length; i++) {
				if (this.smsList[i] == this.programCd) res = this.programCd;
			}
			return res;
		},
		...mapGetters('broadcast', ['broadcastVod']),
	},
	watch: {
		session: function (newVal, oldVal) {
			//console.log(oldVal.userId, " > ", newVal.userId);
			this.getUserSmsList(newVal.userId);
		},
	},
	mounted() {
		this.getClientIP();
		const vgControllerScript = document.createElement('script');
		vgControllerScript.setAttribute('src', 'https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js');
		document.head.appendChild(vgControllerScript);
		this.initVgController(this.isAuth);
		this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
	},
	data: () => ({
		swiperOptionCate: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-component-category .swiper-button-next',
				prevEl: '.broadcast-component-category .swiper-button-prev',
			},
			breakpoints: {
				280: {
					slidesPerView: 1.2,
					spaceBetween: 20,
					// slidesPerView: 1,
				},
				500: {
					slidesPerView: 2.2,
					spaceBetween: 10,
					// slidesPerView: 2,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 4,
				},
			},
		},
		playerUrl: '',
		res: new Object(),
		msgObj: new Object(),
		brcTit: '',
		soundOn: false, // 소리출력 옵션
		liked: false,
		likeCnt: 0,
		recommCnt: 0,
		timerId: null,
		programList: [],
		vodList: [],
		smsList: [],
		programCd: '',
		programNm: '',
		notice: '',
		relLink: '',
		title: '',
		shareOn: false,
		clientIp: '0.0.0.0',
	}),
	created() {
		this.getBrcInfo();
		this.getLiveList();
		if (!this.$cookies.get('gachi-brodcast-noneuser')) {
			this.$cookies.set('gachi-brodcast-noneuser', this.generateRandomString(8), '1d');
		}
	},
	methods: {
		getClientIP() {
			jsonp('https://jsonip.com/', null, (err, data) => {
				if (err) {
					//console.error(err.message);
					this.clientIp = '1.1.1.1';
				} else {
					//console.log(data);
					this.clientIp = data.ip;
				}
			});
		},
		dateDayWeek: function (item) {
			const week = ['일', '월', '화', '수', '목', '금', '토'];
			var date = new Date(item.BRC_SCHDG_BGNG_DT);
			return week[date.getDay()];
		},
		shareFacebook() {
			window.open('http://www.facebook.com/sharer.php?u=' + window.document.location.href);
		},
		copyUrl() {
			//url 넣을 input 생성
			const urlInput = document.getElementById('urlInput');

			//현재 URL 가져오기
			urlInput.value = window.document.location.href;

			//clipboard 복사
			try {
				urlInput.select();
				document.execCommand('copy');
				// alert(`URL 복사 : ${urlInput.value}`);
				alert('URL이 복사 되었습니다.');
			} catch (error) {
				console.error(error);
			}
		},
		getUserSmsList(userId) {
			//사용자SMS발송여부 체크박스 초기화
			this.smsList.splice(0);

			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined') {
				this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: userId,
					})
					.then((res) => {
						var items = getItems(res);
						for (var i = 0; i < items.length; i++) {
							this.smsList.push(items[i].programCd);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		getBrcInfo() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
					brcInfoNo: this.$route.params.brcInfoNo,
					brcTyp: 2,
				})
				.then((res) => {
					console.log('item : ' + JSON.stringify(res.data.VIEW));
					let vod = res.data.VIEW;
					this.playerUrl = vod.PLAYER_URL;
					this.notice = vod.noties[0].NTC_CASE_CN.replaceAll(/(\n|\r\n)/g, '<br>');
					this.brcTit = vod.BRC_TIT;
					if (vod.links[0]) this.relLink = vod.links[0].REL_LINK;
					if (vod.links[0]) this.title = vod.links[0].TITLE;
					this.programCd = vod.PROGRAM_CD;
					this.recommCnt = vod.RECOMM_CNT;
					this.getLiveList();
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getLiveList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					sortCd: '04',
					pageIndex: 1,
					pageSize: 8,
					brcTyp: 2,
				})
				.then((res) => {
					//console.log('res : ' + JSON.stringify(res.data));
					if (res.data.COUNT > 0) {
						this.programList = res.data.PROGRAM_LIST;
						this.liveList = res.data.LIST;
						//console.log('programList : ' + JSON.stringify(this.programList));
						//console.log('this.programList : ', this.programList);

						if (this.programList) {
							this.getVODList(this.programCd);
						}
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getVODList(programCd) {
			this.programCd = programCd;
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					pageIndex: 1,
					brcTyp: 2,
					programCd: programCd,
					brcStsCd: '20',
				})
				.then((res) => {
					//console.log('res : ' + JSON.stringify(res.data));
					this.vodList = res.data.LIST;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		smsListChg2(val) {
			if (typeof this.session.userId == 'undefined') {
				alert('로그인이 필요합니다.');
				this.$router.push({ name: 'Login' });
				return false;
			}

			var deleteFlag = false;
			for (var i = 0; i < this.smsList.length; i++) {
				if (val == this.smsList[i]) {
					deleteFlag = true; //체크박스에서 입력된 데이터가 있으면 insert
				}
			}
			if (deleteFlag) {
				//삭제
				this.$store
					.dispatch(`broadcast/${ACT_DELETE_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				//등록
				this.$store
					.dispatch(`broadcast/${ACT_INSERT_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		// 소리 옵션 토글
		toggleSound() {
			this.soundOn = !this.soundOn;
			controller.mute();
			controller.play();
		},
		//좋아요 이벤트
		addRecommCnt() {
			if (!this.liked) {
				//좋아요 버튼 누를시
				this.likeCnt = 1;
				this.liked = true;
			} else {
				//좋아요 버튼 해제시
				this.likeCnt = -1;
				this.liked = false;
			}

			this.$store
				.dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
					brcInfoNo: this.$route.params.brcInfoNo,
					recommCnt: this.likeCnt,
					clientIP: this.clientIp,
					anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
				})
				.then((res) => {
					//this.getUserSmsList(this.session.userId);
					console.log('res : ' + JSON.stringify(res));
					this.recommCnt = res.data.VIEW.RECOMM_CNT;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		initVgController(isAuth) {
			console.log('initVgController Start');
			controller = new VgControllerClient({
				target_window: document.querySelector('#myplayer').contentWindow,
			});

			controller.on('ready', function () {
				console.log('vg controller ready');
				controller.set_volume(50); // 볼륨 크기
				//controller.mute(); // 음소거
				//controller.play(); // 영상 재생
			});

			controller.on('play', function () {
				if (!isAuth) {
					controller.set_control_visibility(false); // 내장 Controller 숨기:false, 숨김해제:true
				}
			});

			controller.on('progress', function (percent, position, videoTime) {
				//진행백분율(0~100) , 재생위치(초단위), 전체재생길이(초단위)
				//console.log('position : ' + position);
				console.log('재생길이 : ' + videoTime);
				if (!isAuth && position > previewTime) {
					controller.play(0);
					controller.pause();
				}
			});

			controller.on('pause', function () {
				if (!isAuth && !isLoginGuide) {
					isLoginGuide = true;
					alert(`비회원은 3분미리보기만 가능합니다. \n 회원가입후 이용해주세요.`);
					//this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '비회원은 3분미리보기만 가능합니다.'});
				}
			});
		},
	},
};
</script>
